import { createElement, memo, useState } from "react";
import { redirect } from "react-router-dom";

const AcceptPage = memo((props) => {
    return (
        
        <div className="auth-lay" id="app">
                       <div className="auth-card-lay">
                <div className="back-n-title">
                    <img id="back-btn" src="img/back-btn.png" onClick={() => window.location.replace('login')}/>
                    <span className="auth-card-title">Восстановление</span>
                </div>
                <span className="auth-card-def-txt">Email:</span>
                <input/>
                <span className="auth-card-def-txt">Код:</span>
                <input/>
                <button className="login-btn" onClick={() => window.location.replace('nextacceptlog')}>Войти в аккаунт</button>
                
            </div>
        </div>
    )
});

export default AcceptPage;
