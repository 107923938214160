import React, { useEffect, useState, memo } from 'react';
import { Link } from 'react-router-dom';

const SignIn = memo(() => {
    const [username, setUsername] = useState('');
    const [exchange, setExchange] = useState('');
    const [password, setPassword] = useState('');
    const [otpCode, setOtpCode] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [buttonClass, setButtonClass] = useState('login-btn'); // Состояние для класса кнопки

    const [errors, setErrors] = useState({
        username: '',
        exchange: '',
        password: '',
        otpCode: ''
    });

    const handleOrganizationChange = (e) => {
        const value = e.target.value;
        if (value.length <= 3) { // Ограничиваем ввод до 3 символов
            setExchange(value.toUpperCase()); // Приводим к верхнему регистру
        }
    };
    const handleOtpChange = (e) => {
        const value = e.target.value;
        if (value.length <= 6) { // Ограничиваем ввод до 3 символов
            setOtpCode(value.toUpperCase()); // Приводим к верхнему регистру
        }
    };

    useEffect(() => {
        const form = document.getElementById('sign-in');
        form.addEventListener('submit', saveArticle);

        async function saveArticle(event) {
            event.preventDefault(); // предотвратить стандартное поведение формы

            // Валидация
            const newErrors = {
                username: username ? '' : 'Пожалуйста, введите никнейм.',
                exchange: exchange ? '' : 'Пожалуйста, введите организацию.',
                password: password ? '' : 'Пожалуйста, введите пароль.',
                otpCode: otpCode ? '' : ''
            };

            setErrors(newErrors);

            if (newErrors.username || newErrors.exchange || newErrors.password) {
                return;
            }

            setButtonClass('login-btn-def'); // Меняем класс кнопки

            const myFormData = new FormData(form);
            await fetch("https://helpex.tech/api/v1/authorization/login", {
                method: "post",
                body: myFormData,
                credentials: "include"
            })
            .then((res) => {
                
                if (res.ok) {
                    window.location.replace('/chat');
                } else {
                    switch (res.status) {
                        case 401:
                            setErrorMessage('Ошибка входа. Проверьте ваши данные.');
                        case 500:
                            throw new Error('Ошибка сервера. Пожалуйста, попробуйте позже.');
                        default:
                            throw new Error('Произошла неизвестная ошибка.');
                    }
                    setErrorMessage('Ошибка входа. Проверьте ваши данные.');
                }
            })
            .catch(err => {
                setErrorMessage('Произошла ошибка. Попробуйте позже.');
            });
        }

        return () => {
            form.removeEventListener('submit', saveArticle);
        };
    }, [username, exchange, password, otpCode]);

    return (
        <div className="auth-lay" id="app">
            <form action="api.helpex.tech/v1/authorization/login" method="post" className="auth-card-lay" id="sign-in">
                <span className="auth-card-title">Вход</span>
                <span className="auth-card-def-txt">Никнейм пользователя:</span>
                <input 
                    name="username" 
                    value={username} 
                    onChange={(e) => setUsername(e.target.value)} 
                />
                {errors.username && <span className="auth-card-wrong-txt">{errors.username}</span>}
                
                <span className="auth-card-def-txt">Организация:</span>
                <input 
                    name="exchange" 
                    value={exchange.toUpperCase()} 
                    onChange={handleOrganizationChange} 
                />
                {errors.exchange && <span className="auth-card-wrong-txt">{errors.exchange}</span>}
                
                <span className="auth-card-def-txt">Пароль:</span>
                <input 
                    name="password" 
                    type={showPassword ? 'text' : 'password'} 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                />
                {errors.password && <span className="auth-card-wrong-txt">{errors.password}</span>}
                
                <button type="button" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? 'Скрыть' : 'Показать'}
                </button>
                
                <span className="auth-card-def-txt">Двухфакторная защита:</span>
                <input 
                    name="otp_code" 
                    type='number'
                    value={otpCode} 
                    onChange={handleOtpChange} 
                />
                
                <button type="submit" className={buttonClass}>Войти</button> {/* Используем состояние для класса */}
                
                {errorMessage && <span className="auth-card-wrong-txt">{errorMessage}</span>}
                <div className="auth-wrap-lay-2">
                    <span>Нет аккаунта?</span>
                    <Link to="/reg">Регистрация</Link>
                </div>
            </form>
        </div>
    );
});

export default SignIn;
