import { createElement, memo, useState } from "react";

const SecondRegPage = memo((props) => {
    return (
        <div className="auth-lay" id="app">
            <div className="auth-card-lay">
                <div className="back-n-title">
                    <img id="back-btn" src="img/back-btn.png" onClick={() => window.location.replace('reg')}/>
                    <span className="auth-card-title">Регистрация</span>
                </div>
                
                <span className="auth-card-def-txt">Email:</span>
                <input/>
                <div className="auth-wrap-lay">
                    <span>Не приходит код?</span>
                    <a href="">Запросить ещё раз</a>
                </div>
                <span className="auth-card-def-txt">Код:</span>
                <input/>
                <button className="login-btn-def" onClick={() => window.location.replace('lastnextreg')}>Подтвердить код</button>
                
            </div>
        </div>
    )
});

export default SecondRegPage;
