import { memo, useState, useEffect } from "react";
import React from 'react';
import ReactDOM from 'react-dom/client';

const showAlert = (type, title, description) => {
    const container = document.getElementById('root');
    if (!container) return;
    console.log(type, title, description)
    const alertElement = document.createElement('div');
    container.appendChild(alertElement);

    // Создаем корень для рендеринга
    const root = ReactDOM.createRoot(alertElement);
    root.render(<Alert Type={type} Title={title} description={description} />);

    setTimeout(() => {
        root.unmount();
        container.removeChild(alertElement);
    }, 3000);
};



const Alert = memo(({Type, Title, description}) => {
    const types = {
        error: {
            color: '#f83131',
            symbol: '⚠️'
        },
        sucsess: {
            color: '#29CB90',
            symbol: '✅'
        },
        default: {
            color: '#828282',
            symbol: '📝'
        }
    }
    console.log(Type, Title, description)

    return (
        <div className="alert" style={{background: `${types[`${Type}`]['color']}`}}>
            <h3>{types[`${Type}`]['symbol']} {Title}</h3>
            <span>{description}</span>
        </div>
    )
})

export default showAlert;