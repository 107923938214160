import { memo, useEffect, useState } from "react";

const Confirm = ({ Country, Direction, Amount, Discount, WoDelivery, IsFixed, WoRound, setIsConfirm, id }) => {
    const fetchData = async () => {

            try {
                const response = await fetch(`https://helpex.tech/api/v1/panel/calculate/${id}`, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json', // Установка заголовка
                    },
                    body: JSON.stringify({                                                              // Преобразование в JSON
                        "course": Direction,
                        "amount": Number(Amount),
                        "wo_round": WoRound,
                        "country": Country,
                        "wo_delivery": WoDelivery,
                        "is_fixed": IsFixed,
                        "discount": Number(Discount),
                    })
                });

                if (!response.ok){
                    switch (response.status) {
                        case 401:
                            window.location.replace('https://helpex.tech/login')
                        case 403:
                            throw new Error('Ошибка доступа. Пожалуйста, проверьте возможность своего доступа к данному разделу.');
                        case 500:
                            throw new Error('Ошибка сервера. Пожалуйста, попробуйте позже.');
                        default:
                            throw new Error('Произошла неизвестная ошибка.');
                    }
                }

                const data = await response.json();                                                     // Преобразование ответа в JSON
                console.log(data);                                                                      // Выводим данные в консоль (можно удалить или заменить на нужные действия)
            } catch (error) {
                console.error('Ошибка при отправке запроса:', error);                                   // Логируем ошибку
            }
        
        console.log({
                        "course": Direction,
                        "amount": Number(Amount),
                        "wo_round": WoRound,
                        "country": Country,
                        "wo_delivery": WoDelivery,
                        "is_fixed": IsFixed,
                        "discount": Number(Discount),
        });
    };

    
    if (!Country || !Amount) {
        return null; // Если не выбраны, ничего не отображаем
    }

    return (
        <div className="confirm-back-layout" onClick={() => {setIsConfirm(false)}}>
            <div className="confirm-block">
                <div className="list-3-group">
                    <span className="list-3-title" onClick={() => { }}>{Country} | {Direction}</span>
                </div>
                <div className="confirm-body">
                    <div className="confirm-item">
                        <span>Сумма:</span>
                        <span>{Amount}</span>
                    </div>
                    <div className="confirm-item">
                        <span>Скидка:</span>
                        <span>{Discount}</span>
                    </div>
                    <div className="confirm-item">
                        <span>Обмен с фиксированной суммой:</span>
                        {IsFixed ? (<span>✅</span>) : (<span>❌</span>)}
                    </div>
                    <div className="confirm-item">
                        <span>Вычесть доставку:</span>
                        {WoDelivery ? (<span>✅</span>) : (<span>❌</span>)}
                    </div>
                    <div className="confirm-item">
                        <span>Не округлять до 100:</span>
                        {WoRound ? (<span>✅</span>) : (<span>❌</span>)}
                    </div>
                </div>
            </div>
            <span className="confirm-btn" onClick={() => {setIsConfirm(false); fetchData()}}>Подтвердить</span>
        </div>
    );
};


const List = (props) =>{
    const [isClosed, setIsClosed] = useState(false)
    const [isConfirmVisible, setIsConfirmVisible] = useState(false);
    useEffect(() => {
        const fetchUser = async () => {
            const response = await fetch('https://helpex.tech/api/v1/panel/getCourses');
            const data = await response.json();
            console.log(data);
            if (!response.ok){
                switch (response.status) {
                    case 401:
                        window.location.replace('https://helpex.tech/login')
                    case 403:
                        throw new Error('Ошибка доступа. Пожалуйста, проверьте возможность своего доступа к данному разделу.');
                    case 500:
                        throw new Error('Ошибка сервера. Пожалуйста, попробуйте позже.');
                    default:
                        throw new Error('Произошла неизвестная ошибка.');
                }
            }
        };
        fetchUser();
    }, []);
    return(
        <>
        <div className={isClosed ? "list-3-group-act" : "list-3-group"} onClick={() => setIsClosed(!isClosed)} >
            <span className="list-3-title">{props.country.country}</span>
            <img onClick={() => setIsClosed(!isClosed)} src="/img/collapse.png" />
        </div>

        {isClosed && (
            <div className="select-info">
                {props.country.list.map((item) => (
                    <span
                        key={item} // Добавьте ключ для каждого элемента списка
                        className={props.openedPanel === item ? "chats-item-act" : "chats-item"}
                        onClick={() => {
                            props.togglePanel(item);
                            props.setChooseCountry(props.country.country); // Обновляем выбранную страну
                        }}
                    >
                        {item}
                    </span>
                ))}
            </div>
        )}
    </>
    );
}
const CalcLay = memo((props) => {
    const [openedPanel, setOpenedPanel] = useState(null);    
    const [chooseCountry, setChooseCountry] = useState(null);                                            // null означает, что ни один компонент не открыт
    const [num, setNum] = useState('0');
    const [amount, setAmount] = useState('');
    const [wo_round, setWo_round] = useState(false);
    const [wo_delivery, setWo_delivery] = useState(false);
    const [is_fixed, setIs_fixed] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false)
    const togglePanel = (panelName) => {
        setOpenedPanel(openedPanel === panelName ? null : panelName);                                       // Закрыть, если панель уже открыта
    };
    const toggleNum = (Name) => {
        setNum(num === Name ? null : Name);                                                                // Закрыть, если панель уже открыта
    };
    const [countries, setContries] = useState(null)
    useEffect(() => {
        const fetchUser = async () => {
            const response = await fetch('https://helpex.tech/api/v1/panel/getCourses');
            const data = await response.json();
            setContries(data);
            if (!response.ok){
                switch (response.status) {
                    case 401:
                        window.location.replace('https://helpex.tech/login')
                    case 403:
                        throw new Error('Ошибка доступа. Пожалуйста, проверьте возможность своего доступа к данному разделу.');
                    case 500:
                        throw new Error('Ошибка сервера. Пожалуйста, попробуйте позже.');
                    default:
                        throw new Error('Произошла неизвестная ошибка.');
                }
            }
        };
        fetchUser();
    }, []);
    return (
        <div className="fast-wrap" >
            
            {countries && openedPanel === null && countries.map((country) => (
                <List 
                    key={country.country} 
                    country={country} 
                    openedPanel={openedPanel} 
                    togglePanel={togglePanel} 
                    setChooseCountry={setChooseCountry} // Передаем функцию обновления состояния
                />
            ))}
            {
                openedPanel !== null &&
                <div className="list-3-group">
                    <span className="list-3-title" onClick={() => {
                            togglePanel(null);
                            props.setChooseCountry(null); // Обновляем выбранную страну
                        }}>{chooseCountry} | {openedPanel}</span>
                </div>
            }
                <input placeholder="Введите сумму" onChange={(e) => setAmount(e.target.value)} className="list-5-inp"/>
                <div className="list-5-check">
                    <input onClick={() => setIs_fixed(!is_fixed)} type="checkbox"/>
                    <span>Обмен с фиксированной суммой</span>
                </div>
                <div className="list-5-check">
                    <input onClick={() =>setWo_delivery(!wo_delivery)} type="checkbox"/>
                    <span>Вычесть доставку</span>
                </div>
                <div className="list-5-check">
                    <input onClick={() =>setWo_round(!wo_round)} type="checkbox"/>
                    <span>Не округлять до 100</span>
                </div>
                <div className="list-3-group">
                    <span className="list-3-title">Скидка</span>
                </div>
                <div className="select-info">
                <span className={num === "0" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("0")} >0</span>
                    <span className={num === "-0.5" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("-0.5")}>-0.5</span>
                    <span className={num === "-1.0" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("-1.0")}>-1.0</span>
                    <span className={num === "-1.5" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("-1.5")}>-1.5</span>
                    <span className={num === "-2.0" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("-2.0")}>-2.0</span>
                    <span className={num === "+0.5" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("+0.5")}>+0.5</span>
                    <span className={num === "+1.0" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("+1.0")}>+1.0</span>
                    <span className={num === "+1.5" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("+1.5")}>+1.5</span>
                    <span className={num === "+2.0" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("+2.0")}>+2.0</span>
                    <span className={num === "+3.0" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("+3.0")}>+3.0</span>
                    <span className={num === "+4.0" ? "chats-item-act" : "chats-item"} onClick={() => toggleNum("+4.0")}>+4.0</span>
                </div>
                <span className="cl-info" onClick={() => setIsConfirm(true)}>Отправить</span>
                {isConfirm ? <Confirm Country={chooseCountry} Direction={openedPanel} Amount={amount} Discount={num} WoDelivery={wo_delivery} IsFixed={is_fixed} WoRound={wo_round} setIsConfirm={setIsConfirm} id={props.data._id}/> : <></>}
        </div>
    )
});

export default CalcLay;
