import { useEffect, useState, memo } from "react";
import { Link } from "react-router-dom";

const Base64Image = (props) => {
    const imageSrc = `data:image/png;base64,${props.base64String}`;
  
    return (
      <div>
        <img className="qr-img" src={imageSrc} alt="Base64 Image" />
      </div>
    );
};

const RegPage = memo(() => {
    const [username, setUsername] = useState('');
    const [mail, setMail] = useState('');
    const [organization, setOrganization] = useState('');
    const [password, setPassword] = useState('');
    const [obj, setObj] = useState(null);
    const [errors, setErrors] = useState({
        username: '',
        mail: '',
        organization: '',
        password: ''
    });

    useEffect(() => {
        const form = document.getElementById('register-1');
        form.addEventListener('submit', saveArticle);

        async function saveArticle(event) {
            event.preventDefault(); // предотвратить стандартное поведение формы

            // Валидация
            const newErrors = {
                username: username ? '' : 'Пожалуйста, введите никнейм.',
                mail: mail ? '' : 'Пожалуйста, введите email.',
                organization: organization ? '' : 'Пожалуйста, введите организацию.',
                password: password ? '' : 'Пожалуйста, введите пароль.'
            };
            
            setErrors(newErrors);

            if (newErrors.username || newErrors.mail || newErrors.organization || newErrors.password) {
                return; // Прерываем выполнение, если есть ошибки
            }

            const myFormData = new FormData(form);
            try {
                const response = await fetch("https://helpex.tech/api/v1/authorization/register", {
                    method: "POST",
                    body: myFormData
                });
                if (!response.ok) {
                    switch (response.status) {
                        case 401:
                            throw new Error('Ошибка входа. Проверьте ваши данные.');
                        case 500:
                            throw new Error('Ошибка сервера. Пожалуйста, попробуйте позже.');
                        default:
                            throw new Error('Произошла неизвестная ошибка.');
                    }
                }
                const data = await response.json(); // Преобразование ответа в JSON
                console.log(data); // Выводим данные в консоль
                setObj(data);
            } catch (error) {
                console.error('Ошибка при отправке запроса:', error); // Логируем ошибку
            }
        }

        return () => {
            form.removeEventListener('submit', saveArticle);
        };
    }, [username, mail, organization, password]);

    const handleOrganizationChange = (e) => {
        const value = e.target.value;
        if (value.length <= 3) { // Ограничиваем ввод до 3 символов
            setOrganization(value.toUpperCase()); // Приводим к верхнему регистру
        }
    };
    

    return (
        <div className="auth-lay" id="app">
            {obj === null && (
                <form action="https://helpex.tech/api/v1/authorization/register" method="post" className="auth-card-lay" id="register-1">
                    <span className="auth-card-title">Регистрация</span>
                    
                    <span className="auth-card-def-txt">Никнейм пользователя:</span>
                    <input 
                        name="username" 
                        value={username} 
                        onChange={(e) => setUsername(e.target.value)} 
                    />
                    {errors.username && <span className="auth-card-wrong-txt">{errors.username}</span>}
                    
                    <span className="auth-card-def-txt">Mail:</span>
                    <input 
                        name="mail" 
                        value={mail} 
                        onChange={(e) => setMail(e.target.value)} 
                    />
                    {errors.mail && <span className="auth-card-wrong-txt">{errors.mail}</span>}
                    
                    <span className="auth-card-def-txt">Организация:</span>
                    <input 
                        name="organization" 
                        value={organization} 
                        onChange={handleOrganizationChange} // Используем новую функцию для обработки изменения
                    />
                    {errors.organization && <span className="auth-card-wrong-txt">{errors.organization}</span>}
                    
                    <span className="auth-card-def-txt">Пароль:</span>
                    <input 
                        name="password" 
                        type="password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                    />
                    {errors.password && <span className="auth-card-wrong-txt">{errors.password}</span>}
                    
                    <button className="login-btn" type="submit">Получить токен</button>
                    
                    <div className="auth-wrap-lay-2">
                        <span>Уже есть аккаунт?</span>
                        <a href="/login">Вход</a>
                    </div>
                </form>
            )}
            {obj !== null && (
                <form className="auth-card-lay-qr" id="register-2">
                    <span className="auth-card-title">Регистрация</span>
                    <span className="auth-card-title">QR-code</span>
                    <div>
                        <span className="auth-card-def-txt">Токен:</span>
                        <input value={obj.otp_secret} readOnly />
                        <Link to="https://helpex.tech/login">
                            <button className="login-btn">Войти</button>
                        </Link>
                        <div style={{flexDirection: 'row'}} className="auth-wrap-lay-2-qr">
                            <a style={{width: "40%"}} href="/login">Google аутентификатор</a>
                            <a style={{width: "40%"}} href="/login">Microsoft аутентификатор</a>
                        </div>
                    </div>
                    <div>
                        <Base64Image base64String={obj.otp_qr} />
                    </div>
                </form>
            )}
        </div>
    );
});

export default RegPage;
