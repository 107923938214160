import { createElement, memo, useState } from "react";

const ThirdRegPage = memo((props) => {
    return (
        <div className="auth-lay" id="app">
            <div className="auth-card-lay">
                <span className="auth-card-title">Регистрация</span>
                <span className="auth-card-def-txt">Введите пароль:</span>
                <input/>
                <span className="auth-card-wrong-txt">Пароль должен состоять из цифр 0-9 и букв латинского алфавита a-z, A-Z</span>
                <span className="auth-card-def-txt">Подтвердите пароль:</span>
                <input/>
                <span className="auth-card-wrong-txt">Пароли не совпадают</span>
                <button className="login-btn-def" onClick={() => window.location.replace('login')}>Завершить регистрацию</button>
            </div>
        </div>
    )
});

export default ThirdRegPage;
