import { memo, useState, useEffect } from "react";

const InfoLay = memo((props) => {
    
    return (
        <div className="fast-wrap" >
                <span className="cl-info" style={{backgroundColor: 'white', color: '#539CF1', border: '2px solid #539CF1'}}>Копировать сделку</span>
                <div className="list-3-group">
                    <span className="list-3-title">Статус:</span>
                </div>
                <span className="cl-info" style={{backgroundColor: '#539CF1'}}>Партнеры</span>
                <div className="list-3-group">
                    <span className="list-3-title">Статус:</span>
                </div>
                <span className="cl-info" style={{width: '90%', textAlign: 'left', marginTop: '2%'}}>Источник</span>
                <div className="list-3-group">
                    <span className="list-3-title">Информация по клиенту:</span>
                </div>
                <textarea name=""></textarea>
        </div>
    )
});

export default InfoLay;
