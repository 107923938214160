import { memo, useState , useEffect, useMemo } from "react";


const Table = memo((props) => {
    const fake_data = {"operations": [], "total_amount": 0}
    const [data, setData] = useState(fake_data)
    const [Contractors, setContrators] = useState([])
    const [openedContractor, setOpenedContractor] = useState('default');
    const togglePanel = (section) => {
        setOpenedContractor(openedContractor === section ? null : section);
        console.log(section)
    };
    useEffect(() => {
        const fetchTable = async () => {
            const response = await fetch(`https://helpex.tech/api/v1/acceptance/getOperations`);
            const data = await response.json();
            if (!response.ok){
                setData(fake_data)
                switch (response.status) {
                    case 401:
                        window.location.replace('https://helpex.tech/login')
                    case 403:
                        throw new Error('Ошибка доступа. Пожалуйста, проверьте возможность своего доступа к данному разделу.');
                    case 500:
                        throw new Error('Ошибка сервера. Пожалуйста, попробуйте позже.');
                    default:
                        throw new Error('Произошла неизвестная ошибка.');
                }
            }else{
                setData(data)
            }
            console.log(data)
            
        };
        fetchTable();
    }, [openedContractor]);


    return (
        <div className="main-lay">
            <div className="nav_pan">
            <img id="chat" className="panel_item" src="img/leftNavBarPics/chat-def.png" onClick={() => window.location.replace('chat')}></img>
        <img className="panel_item" src="img/leftNavBarPics/exchange.png" onClick={() => window.location.replace('exchange')}></img>
        <img className="panel_item" src="/img/leftNavBarPics/exchange-act.png" onClick={() => window.location.replace('table')} />
        <img className="panel_item" src="img/leftNavBarPics/referal-def.png" onClick={() => window.location.replace('referals')}></img>
        <img className="panel_item_exit" src="img/leftNavBarPics/exit.png" onClick={() => {fetch('https://helpex.tech/api/v1/authorization/logout', {method: 'POST'}); window.location.reload()}}></img>
            </div>
            <div className="result-table-wrap">

                
                <div className="table">
                    <h1>Накопленный Баланс: {data.total_amount}</h1>
                <div className="table-title">
                            <span>ID</span>
                            <span>Дата</span>
                            <span>Сумма</span>
                            <span>Комиссия</span>
                            <span>Комментарий</span>
                        </div>
                        <hr/>
                    {data !== null && data.operations.map((item) => (
                        <>
                        <div className="table-item">
                            <span>{item._id}</span>
                            <span>{item.date}</span>
                            <span>{item.amount}</span>
                            <span>0</span>
                            <span>{item.comment}</span>
                        </div>
                        <hr/>
                        </>
                                    ))}


                    
                    
                </div>
            </div>
        </div>
    )
});

export default Table;