import { memo, useState, useEffect } from "react";
import showAlert from "../Alert";
const ExchangePanel = memo((props) => {
    const [data, setData] = useState(props.data);
    const [btns, setBtns] = useState([]);
    const [isDataUpdated, setIsDataUpdated] = useState(false); // Флаг для отслеживания обновления

    useEffect(() => {
        if (props.data !== data) {
            setData(props.data);
            setIsDataUpdated(true); // Устанавливаем флаг, когда данные обновлены
        }
    }, [props.data]); // Убираем data из зависимостей, чтобы избежать бесконечного цикла

    useEffect(() => {
        const fetchBtns = async () => {
            if (data) {
                try {
                    const response = await fetch(`https://helpex.tech/api/v1/panel/getCurrentStatuses/${data.status}`);
                    const result = await response.json();
                    setBtns(result);
                    console.log(result);
                    if (!response.ok){
                        switch (response.status) {
                            case 401:
                                window.location.replace('https://helpex.tech/login')
                            case 403:
                                throw new Error('Ошибка доступа. Пожалуйста, проверьте возможность своего доступа к данному разделу.');
                            case 500:
                                throw new Error('Ошибка сервера. Пожалуйста, попробуйте позже.');
                            default:
                                throw new Error('Произошла неизвестная ошибка.');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching buttons:', error);
                }
            }
        };

        fetchBtns();
    }, [data]);

    if (!data) return null; // Возвращаем null, если данных нет

    return (
        <div className="fast-wrap">
            {btns.length > 0 && btns.map((item) => (
                <form key={item.status} style={{ width: '95%' }} id={item.status} onSubmit={async (event) => {
                    event.preventDefault(); // предотвратить стандартное поведение формы

                    if (!isDataUpdated) return; // Если данные еще не обновлены, ничего не делаем

                    const myFormData = new FormData(event.target);
                    console.log([...myFormData.entries()]);

                    try {
                        const res = await fetch(`https://helpex.tech/api/v1/tickets/setStatus/${data._id}`, {
                            method: "POST",
                            body: myFormData,
                            headers: {
                                'Accept': 'application/json',
                            }
                        });

                        if (res.ok) {
                            showAlert('sucsess', 'Статус сделки измененен', '')
                            console.log(res, data["status"]);
                        } else {
                            showAlert('error', 'Статус сделки  не измененен', '')
                            console.log(res);
                            switch (res.status) {
                                case 401:
                                    window.location.replace('https://helpex.tech/login')
                                case 403:
                                    throw new Error('Ошибка доступа. Пожалуйста, проверьте возможность своего доступа к данному разделу.');
                                case 500:
                                    throw new Error('Ошибка сервера. Пожалуйста, попробуйте позже.');
                                default:
                                    throw new Error('Произошла неизвестная ошибка.');
                            }
                        }
                    } catch (err) {
                        alert('An error occurred while changing status.');
                        console.error(err);
                        window.location.replace('/login');
                    }
                }}>
                    <input name="status" value={item.status} style={{ display: 'none' }} />
                    <button style={{backgroundColor: item.bg_color, color: 'white'}} className="copy" type='submit'>{item.title}</button>
                </form>
            ))}
        </div>
    );
});

export default ExchangePanel;
