import { useState, memo, useEffect} from "react";

const Switcher = () => {
    const [isActive, setIsActive] = useState(false);
    const toggleStatus = () => {
        setIsActive(prev => !prev);
        fetch('https://helpex.tech/api/v1/profile/changeStatus', { method: 'POST' });
    };

    return (
        <div className="change_line">
            <div
                className="switcher"
                id="switcher"
                onClick={toggleStatus}
                style={{
                    backgroundColor: isActive ? '#ccc' : '#1C66D1',
                    cursor: 'pointer',
                    padding: '5px',
                    borderRadius: '20px',
                    position: 'relative',
                }}
            >
                <div
                    className="circle"
                    id="circle"
                    style={{
                        width: '20px',
                        height: '20px',
                        backgroundColor: '#fff',
                        borderRadius: '50%',
                        position: 'absolute',
                        top: '40%',
                        left: isActive ? 'calc(100% - 25px)' : '0',
                        transform: 'translateY(-50%)',
                        transition: 'left 0.3s',
                    }}
                ></div>
            </div>
            <span
                id="status"
                style={{ color: isActive ? '#000000' : '#006BD3' }}
            >
                {isActive ? 'Оффлайн' : 'Онлайн'}
            </span>
        </div>
    );
};

const TopNavBar = (props) => {
    const [prof_data, setProf_data] = useState('');
    const [pars_data, setPars_data] = useState(null);
    const [showCourses, setShowCourses] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [dealCount, setDealCount] = useState(null)
    

    useEffect(() => {
        const fetchCourses = async () => {
            const response = await fetch('https://parser.helpex.tech/');
            const data = await response.json();
            console.log(data)
            setPars_data(data);
        };
        fetchCourses();
    }, []);

    useEffect(() => {
        try{
            const fetchCount = async () => {
                const response = await fetch('https://reception.helpex.tech/api/v1/reception/countTickets');
                const data = await response.json();
                console.log(data)
                setDealCount(data);
                fetchCount();
            };
        }catch(err){
            console.log('Error:', err)
        }
        
        
        
    }, []);

    useEffect(() => {
        const fetchUser = async () => {
            const response = await fetch('https://helpex.tech/api/v1/profile/getMe');
            const data = await response.json();
            setProf_data(data);
        };
        fetchUser();
    }, []);

    const toggleCourses = () => {
        setShowCourses(prev => !prev);
    };

    const handleCourseClick = (course) => {
        setSelectedCourse(prev => (prev === course ? null : course));
    };


    function updateTime() {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        const timeString = `${hours}:${minutes}`;
        window.onload = () => document.getElementById('clock').textContent = timeString;
    }

    // Обновляем время каждую секунду
    setInterval(updateTime, 1000);
    
    // Вызываем функцию один раз, чтобы сразу отобразить текущее время
    updateTime();
    

    return (
        <div id="top_nav-bar_wrapper">
            <nav class="top_nav-bar">
                <img className="nav_img" onClick={() => window.location.replace('profile')}/>
                <div className="params_wrap2">
                    <span>Сделки</span>
                    <div className="white_wrap2">
                        <span>{dealCount !== null ? dealCount : '-'}</span>
                    </div>
                    <span>Рубль</span>
                    <div className="white_wrap2">
                        <span>{pars_data != null && parseFloat(pars_data.CBR["RUB-USD"]["buy"]).toFixed(4)}</span>
                    </div>
                    <span>USDT</span>
                    <div className="white_wrap2">
                        <span>{parseFloat(pars_data != null && 1 / pars_data.CBR["RUB-USD"]["buy"]).toFixed(4)}</span>
                    </div>
                    <span>Ручной курс</span>
                    <div className="white_wrap2">
                        <span>{pars_data != null && pars_data.GARANTEEX["RUB-USDT"]["sell"].toFixed(4)}</span>
                    </div>
                    
                </div>
                <div id="clock"></div>
                <div className="courses">
                
            <button onClick={toggleCourses}>
                {showCourses ? "Скрыть курсы" : "Показать курсы"}
            </button>
        
                    
                </div>
                
                <Switcher/>
                <div className="nav-prof">
                    <img src="/img/topNavBarPics/profile.svg"/>
                    <span>{prof_data.username}</span>
                </div>
            </nav>
        </div>
    );
};

export default TopNavBar;
