import { memo, useState, useEffect } from "react";

const List = (props) => {
    const [isClosed, setIsClosed] = useState(false);


    const handleSubmit = async (text) => {
        const myFormData = new FormData();
        myFormData.append('text', text);
    
        try {
            const res = await fetch(`https://helpex.tech/api/v1/chats/ticket/${props.ex}/${props._id}/sendmessage`, {
                method: "POST",
                body: myFormData,
                headers: {
                    'Accept': 'application/json',
                }
            });
    
            if (res.ok) {
                alert('Sell is success');
                console.log(res);
            } else {
                alert('Sell is not success');
                console.log(res);
                switch (res.status) {
                    case 401:
                        window.location.replace('https://helpex.tech/login')
                    case 403:
                        throw new Error('Ошибка доступа. Пожалуйста, проверьте возможность своего доступа к данному разделу.');
                    case 500:
                        throw new Error('Ошибка сервера. Пожалуйста, попробуйте позже.');
                    default:
                        throw new Error('Произошла неизвестная ошибка.');
                }
            }
        } catch (err) {
            window.location.replace('/login');
        }
    };

    return (
        <>
            <div className={isClosed ? "list-3-group-act" : "list-3-group"} onClick={() => setIsClosed(!isClosed)} >
                <span className={"list-3-title"}>{props.data.category}</span>
                <img 
                    src="/img/collapse.png" 
                    alt="Toggle collapse" 
                />
            </div>

            {isClosed && (
                <div className="select-info">
                    {props.data.items.map((item) => (
                        <span
                            key={item.id} 
                            className="chats-item"
                            onClick={() => handleSubmit(item.template)}
                        >
                           {item.template}
                        </span>
                    ))}
                </div>
            )}
        </>
    );
};

const TempPanel = memo((props) => {
    const [temp, setTemp] = useState({}); // Изменяем начальное состояние на объект

    function groupByCategory(arr) {
        return arr.reduce((acc, item) => {
            if (!acc[item.category]) {
                acc[item.category] = []; // Создаем новый массив для этой категории
            }
            acc[item.category].push(item); // Добавляем текущий элемент в соответствующую категорию
            return acc;
        }, {});
    }

    useEffect(() => {
        const fetchUser = async () => {
            const response = await fetch('https://helpex.tech/api/v1/templates/getTemplates');
            const data = await response.json();
            setTemp(groupByCategory(data)); // Устанавливаем объект категорий
            if (!response.ok){
                switch (response.status) {
                    case 401:
                        window.location.replace('https://helpex.tech/login')
                    case 403:
                        throw new Error('Ошибка доступа. Пожалуйста, проверьте возможность своего доступа к данному разделу.');
                    case 500:
                        throw new Error('Ошибка сервера. Пожалуйста, попробуйте позже.');
                    default:
                        throw new Error('Произошла неизвестная ошибка.');
                }
            }
        };
        
        fetchUser();
    }, []);

    return (
        <div className="fast-wrap">
            {Object.keys(temp).map((category) => ( // Используем Object.keys для перебора категорий
                <List _id={props.data._id} ex={props.data.exchange} key={category} data={{ category, items: temp[category] }} /> // Передаем категорию и элементы
            ))}
        </div>
    );
});

export default TempPanel;
