import { memo, useState, useEffect } from "react";

const Payments = memo((props) => {
    
    return (
        <div className="fast-wrap" >
                <div className="list-3-group">
                    <span className="list-3-title">Реквезиты:</span>
                </div>
                <div className="list-3-group">
                    <span className="list-3-title" style={{fontSize: '120%'}}>Неуказаны</span>
                </div>
                <div className="list-3-group">
                    <span className="list-3-title">Накопленный баланс:</span>
                </div>
                <span className="cl-info">Нет выплат</span>
                <select>
                    <option>Выберите счет</option>
                    <option>Выберите счет</option>
                    <option>Выберите счет</option>
                    <option>Выберите счет</option>
                    <option>Выберите счет</option>
                </select>
                <span className="list-7-item">Копировать в сумму</span>
                <div className="list-3-group">
                    <span className="list-3-title">Сумма:</span>
                </div>
                <div className="list-7-inp">
                    <input placeholder="Введите сумму" />
                    <span>+10</span>
                </div>
                <span className="list-7-item">Сохранить</span>
                <div className="cl-text-info-l-7">
                    <span className="main-cl-text-color">ID</span>
                    <span className="main-cl-text-color">Карта</span>
                    <span className="main-cl-text-color">Сумма</span>
                </div>
                <div className="cl-text-info-l-7">
                    <span className="second-cl-text-color">12981</span>
                    <span className="second-cl-text-color">203946322348</span>
                    <span className="second-cl-text-color">500.000</span>
                </div>
        </div>
    )
});

export default Payments;
