import { memo, useState , useEffect } from "react";
import InfoLay from "./PanelLays/InfoLay";
import Payments from "./PanelLays/Payments";

const PanelRef = memo((props) => {
    const [data, setData] = useState(props.data)
    useEffect(() => {
        setData(props.data);
        console.log(data)
    }, [props.data]);
    const [openedPanel, setOpenedPanel] = useState('info'); // null означает, что ни один компонент не открыт

    const togglePanel = (panelName) => {
        setOpenedPanel(openedPanel === panelName ? null : panelName); // Закрыть, если панель уже открыта,

    };
    

    return (
        <div className="info-panel">
            <div className="info-panel" style={{width: '100%'}} >
            {data !== null &&
            <span className="cl-info">{ data._id} | {data.first_name}</span>}
            <div className="select-info">
                <span className={openedPanel === "info" ? "chats-item-act" : "chats-item"} onClick={() => togglePanel("info")}>Обмен</span>
                <span className={openedPanel === "payment" ? "chats-item-act" : "chats-item"} onClick={() => togglePanel("payment")}>Выплаты</span>
            </div>
            {openedPanel === "info" && (
                <InfoLay togglePanel={togglePanel} />
            )}
            {openedPanel === "payment" && (
                <Payments togglePanel={togglePanel} />
            )}
        </div>
    </div>
    );
});

export default PanelRef;